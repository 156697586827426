import last from 'lodash/last';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { earlyInUseEffect } from '../../utils';
import { objectToPromoEventTransformer, selectPromotion, viewPromotion } from '../../utils/analytics';
import CarouselSectionSlideImage from '../CarouselSectionSlideImage/CarouselSectionSlideImage';

import styles from './CarouselSectionSlide.module.scss';

const GA_SLIDE_CREATIVE_TAG = 'homepage_carousel';

export default function CarouselSection({ index, slide }) {
  const router = useRouter();
  const { ref, inView } = useInView({ threshold: 0.25 });

  function onInView() {
    const name = slide?.text;
    const image = slide?.icon?.path;
    const promo = objectToPromoEventTransformer(name, image, GA_SLIDE_CREATIVE_TAG, index);

    viewPromotion(promo);
  }

  function onClick() {
    const name = slide?.text;
    const image = slide?.icon?.path;
    const promo = objectToPromoEventTransformer(name, image, GA_SLIDE_CREATIVE_TAG, index);
    const redirectUrl = slide.redirect_url;
    selectPromotion(promo);

    if (!redirectUrl) return;
    const url = new URL(redirectUrl);
    const urlFullPath = last(url.toString().split(url.hostname));
    router.push(urlFullPath);
  }

  useEffect(() => {
    if (!inView) return earlyInUseEffect;
    onInView();
  }, [inView]);

  return (
    <div ref={ref} className={styles.container} key={slide.icon.id} onClick={onClick} role="link" tabIndex="-1">
      <CarouselSectionSlideImage path={slide.icon.path} alt={slide.text || 'προσφορά'} index={index} />
    </div>
  );
}
