import Slider from '@ant-design/react-slick';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useState, Suspense, useEffect } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';

import { OFFERS, CATEGORIES } from '../../constants/routes';
import { RenderedProducts } from '../../store/products';
import { addToAnalyticsGenericEvent, selectPromotion, viewPromotion } from '../../utils/analytics';
import NextArrow from '../swiper-buttons/NextArrow';
import PrevArrow from '../swiper-buttons/PrevArrow';

import styles from './Categories.module.scss';

const CategoriesModal = dynamic(() => import('../CategoriesModal/CategoriesModal'), {
  suspense: true,
});

const SLIDER_SETTINGS = {
  adaptiveHeight: true,
  className: styles.slider,
  infinite: false,
  slidesToShow: 8,
  slidesToScroll: 8,
  speed: 500,
  prevArrow: <PrevArrow category onClick={() => addToAnalyticsGenericEvent('categoryLeftClick')} />,
  nextArrow: <NextArrow category onClick={() => addToAnalyticsGenericEvent('categoryRightClick')} />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 620,
      settings: {
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 358,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: true,
      },
    },
  ],
};

export default function Categories({ categories = [] }) {
  const [showCategories, setShowCategories] = useState(false);

  function onClickLink(category, index) {
    RenderedProducts.update({});
    selectPromotion({
      creative_slot: 'Categories in Home',
      creative_name: 'Categories in Home',
      items: [{ index, item_category: category }],
    });
  }

  useEffect(() => {
    setTimeout(() => {
      viewPromotion({
        creative_slot: 'Categories in Home',
        creative_name: 'Categories in Home',
      });
    }, 1000);
  }, []);

  return (
    <section className={styles.container}>
      <section className={styles.sliderContainer}>
        {!categories.length ? (
          <Skeleton height={160} />
        ) : (
          <Slider {...SLIDER_SETTINGS}>
            <Link href={OFFERS}>
              <a
                className={styles.tile}
                data-cy="offers"
                onClick={() => {
                  onClickLink('Προσφορές');
                }}
              >
                <div className={styles.imgContainer}>
                  <div className={styles.offers} />
                </div>
                <h2 className={styles.title}>Προσφορές</h2>
                <p className={styles.count}>Ανακαλύψτε τες</p>
              </a>
            </Link>

            {categories.map(({ divisionCode, name, numberOfProductsToDisplay, slugAscii }, index) => (
              <Link href={`${CATEGORIES}/${slugAscii}`} as={`${CATEGORIES}/${slugAscii}`} key={divisionCode}>
                <a
                  className={styles.tile}
                  onClick={() => onClickLink(name)}
                  data-cy={slugAscii}
                  aria-hidden={index > 6}
                >
                  <div className={styles.imgContainer}>
                    <div className={styles[slugAscii]} />
                  </div>
                  <h2 className={styles.title}>{name}</h2>
                  <p className={styles.count}>
                    {numberOfProductsToDisplay ? `${numberOfProductsToDisplay} Προϊόντα` : ''}
                  </p>
                </a>
              </Link>
            ))}
          </Slider>
        )}
      </section>

      <div className={styles.viewAllContainer}>
        <button type="button" className={styles.categoriesBtn} onClick={() => setShowCategories(true)}>
          <span>Δες όλες τις κατηγορίες</span>
          <MdKeyboardArrowRight className={styles.arrowIcon} alt="Δες όλες τις κατηγορίες" />
        </button>
      </div>
      {showCategories && (
        <Suspense fallback={<p />}>
          <CategoriesModal isOpen={showCategories} setIsOpen={setShowCategories} slug="" />
        </Suspense>
      )}
    </section>
  );
}
