import dynamic from 'next/dynamic';
import React, { useState, useEffect, useMemo, Suspense } from 'react';

/* Components */
import styles from '../../styles/pages/index.module.scss';
import { getHomepageSections, getFooterUSP, getPromotedProducts } from '../api/cms';
import CustomMetaHead from '../components/CustomMetaHead';
import DeliveryBanner from '../components/DeliveryBanner/DeliveryBanner';
import Footer from '../components/Footer/Footer';
import FooterUSP from '../components/FooterUSP/FooterUSP';
import Header from '../components/Header/Header';
import CarouselSection from '../components/Homepage/CarouselSection';
import SingleImageBanner from '../components/Homepage/SingleImageBanner';
import Categories from '../components/Index/Categories';
import Navigation from '../components/Navigation/Navigation';
import OfferBannerSection from '../components/OfferBannerSection/OfferBannerSection';
import META_DATA from '../constants/metaData';
import CategoriesStore from '../store/categories';
import { getOrFetchFooterLinks } from '../utils/footerLinks';
import { getOrFetchOffers } from '../utils/getOffers';
import { getOrFetchNavigationLinks } from '../utils/navigationLinks';
import { serverSideFetchingFromFile } from '../utils/server-side/products';

const HomepageOffersWrapper = dynamic(() => import('../components/OfferSection/HomepageOffersWrapper'), {
  suspense: true,
});

const OfferBannerSectionBottom = dynamic(() => import('../components/OfferBannerSection/OfferBannerSection'), {
  suspense: true,
});

export default function Index({
  navigation,
  sections = {},
  footerLinks,
  footerUSP,
  promotedProducts,
  offers,
  meta,
  preBuildProducts,
}) {
  const [categories, setCategories] = useState([]);
  const [showBottomContent, setShowBottomContent] = useState(false);

  function callback(entry) {
    if (entry[0].isIntersecting) {
      setShowBottomContent(true);
    }
  }

  useEffect(() => {
    const categories$ = CategoriesStore.subscribe(setCategories);
    const hash = window.location.hash.substring(1);
    if (hash && hash === 'newsletter') {
      const element = document.documentElement;
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }

    const options = {
      threshold: 0.25,
    };
    const observer = new IntersectionObserver(callback, options);
    const target = document.querySelector('#listContainer');
    observer.observe(target);
    return () => {
      categories$.unsubscribe();
    };
  }, []);

  const offerBannerSection = useMemo(
    () => (
      <OfferBannerSection sections={sections.prosforesnea?.fields?.sections} analyticsTitle="links under home banner" />
    ),
    []
  );

  const carousel = useMemo(() => <CarouselSection data={sections.carousel} />, []);

  const categoriesMemo = useMemo(() => <Categories categories={categories} />, [categories]);

  const footerUsp = useMemo(() => <FooterUSP footerUSP={footerUSP} />, []);

  const deliveryBannerMemo = useMemo(() => <DeliveryBanner />, []);

  const headerNavigation = useMemo(
    () => (
      <div className={styles.headerContainer}>
        <Header navigation={navigation} isEnabled />
        <Navigation navigation={navigation} />
      </div>
    ),
    []
  );

  const footerMemo = useMemo(() => <Footer footerLinks={footerLinks} footerUSP={footerUSP} />, []);

  const CustomMetaHeadMemo = useMemo(
    () => (
      <CustomMetaHead title="Κατάστημα Online" meta={meta}>
        <meta name="google-site-verification" content={meta.googleVerification} />
      </CustomMetaHead>
    ),
    []
  );

  return (
    <>
      {CustomMetaHeadMemo}

      {headerNavigation}

      {deliveryBannerMemo}

      {categoriesMemo}

      {carousel}

      {offerBannerSection}

      {showBottomContent && (
        <Suspense fallback={<p />}>
          <HomepageOffersWrapper
            promotedProducts={promotedProducts}
            offers={offers}
            preBuildProducts={preBuildProducts}
          />
        </Suspense>
      )}

      {showBottomContent && (
        <Suspense fallback={<p />}>
          <OfferBannerSectionBottom title={sections.products?.title} sections={sections.products?.fields?.sections} />
        </Suspense>
      )}

      {sections.howto && <SingleImageBanner data={sections.howto} />}
      {footerUsp}
      {footerMemo}
    </>
  );
}

export async function getStaticProps() {
  const [
    { data: navigation },
    { data: _sections },
    footerLinks,
    { data: footerUSP },
    { data: promotedProducts },
    allOffers,
  ] = await Promise.all([
    getOrFetchNavigationLinks(),
    getHomepageSections(),
    getOrFetchFooterLinks(),
    getFooterUSP(),
    getPromotedProducts(),
    getOrFetchOffers(),
  ]);
  const sections = _sections.reduce((acc, curr) => ({ ...acc, [curr.slug]: curr }), {});
  const meta = META_DATA[process.env.appName].homepage;
  const dateNow = Math.round(new Date().getTime() / 1000);
  const offers = allOffers.filter(o => {
    return o.available && o.enabled && o.beginDate < dateNow && o.endDate > dateNow;
  });

  const productOffersIds = promotedProducts[0]?.fields['product-offers'] || '';
  const allProducts = serverSideFetchingFromFile();
  const productOffersRemoveSpace = productOffersIds.replace(/\s/g, '');
  const productOffersArray = productOffersRemoveSpace.split(',');
  const onlyProducts = productOffersArray.filter(item => item.includes('PR'));

  const productOffers = onlyProducts.map(item => {
    return allProducts.find(p => p.sku === item.substring(2));
  });

  const preBuildProducts = productOffers.filter(item => item);

  return {
    props: {
      navigation,
      sections,
      footerLinks,
      footerUSP,
      promotedProducts,
      meta,
      offers,
      preBuildProducts,
    },
  };
}
