import styles from './FooterUSPItem.module.scss';

function FooterUSPItem({ imgUrl, header, description }) {
  const replaced = description.replace(/<\/?p[^>]*>/g, '');
  return (
    <div className={styles.container}>
      <img className={styles.img} src={imgUrl} alt="πολιτικές super market" />
      <div className={styles.text}>
        <div className={styles.header}>{header}</div>
        <p className={styles.description}>{replaced}</p>
      </div>
    </div>
  );
}

export default FooterUSPItem;
