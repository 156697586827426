import styles from './FormattedImage.module.scss';

export default function FormattedImage({ path, alt, width, height, loading }) {
  return (
    <picture>
      <source srcSet={`${path}?w=${width * 2}&format=webp`} type="image/webp" />
      <source srcSet={`${path}?w=${width * 2}`} type="image/jpeg" />

      <img width={width} height={height} srcSet={`${path}`} className={styles.img} alt={alt} loading={loading} />
    </picture>
  );
}
