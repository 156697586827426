import classNames from 'classnames';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';

import styles from './PrevArrow.module.scss';

export default function PrevArrow({ onClick, carousel, smallArrow, leaflet, category }) {
  return (
    <button
      className={classNames({
        [styles.categoryContainer]: category,
        [styles.container]: !carousel,
        [styles.carouselContainer]: carousel,
        [styles.smallArrow]: smallArrow,
        [styles.leaflet]: leaflet,
      })}
      onClick={onClick}
      aria-label="previous"
    >
      <MdOutlineKeyboardArrowLeft className={styles.icon} alt="Arrow back - Arrow left" />
    </button>
  );
}
