import FooterUSPItem from '../FooterUSPItem/FooterUSPItem';

import styles from './FooterUSP.module.scss';

export default function FooterUSP({ footerUSP = [] }) {
  if (!footerUSP.length) return null;

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        {footerUSP.map(usp => (
          <FooterUSPItem
            key={usp.uuid}
            imgUrl={usp.fields.sections[0].image.path}
            header={usp.fields.sections[0].heading}
            description={usp.fields.sections[0].text}
          />
        ))}
      </div>
    </section>
  );
}
