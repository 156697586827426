import Link from 'next/link';

import styles from './SingleImageBanner.module.scss';

function SingleImageBanner({ data: { text, fields } }) {
  const {
    icon: { path },
    redirect_url: href,
  } = fields.sections[0];

  return (
    <section className={styles.container}>
      <Link href={href || '#'}>
        <a>
          <img className={styles.img} src={path} alt={text} />
        </a>
      </Link>
    </section>
  );
}

export default SingleImageBanner;
