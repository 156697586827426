import OfferBanner from './OfferBanner';
import styles from './OfferBannerSection.module.scss';

export default function OfferBannerSection({ title = '', sections = [], analyticsTitle }) {
  if (!sections.length) return null;

  return (
    <section className={styles.section}>
      {title && <h2 className={styles.sectionTitle}>{title}</h2>}

      <div id="listContainer" className={styles.listContainer}>
        {sections.map(
          s => (
            <OfferBanner key={s.redirect_url} banner={s} sections={sections} title={analyticsTitle || 'Our Products'} />
          ),
          []
        )}
      </div>
    </section>
  );
}
