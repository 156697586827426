import Slider from '@ant-design/react-slick';

import NextArrow from '../swiper-buttons/NextArrow';
import PrevArrow from '../swiper-buttons/PrevArrow';

import styles from './CarouselSection.module.scss';
import CarouselSectionSlide from './CarouselSectionSlide';

export default function CarouselSection({ data }) {
  const slides = data?.fields?.sections || [];

  return (
    <section className={styles.color}>
      <Slider
        {...{
          autoplay: true,
          autoplaySpeed: 5000,
          className: styles.slider,
          infinite: true,
          nextArrow: <NextArrow carousel />,
          prevArrow: <PrevArrow carousel />,
          slidesToScroll: 1,
          slidesToShow: 1,
          speed: 500,
        }}
      >
        {slides.map((slide, index) => (
          <CarouselSectionSlide {...{ index, slide, key: slide.icon.id }} />
        ))}
      </Slider>
    </section>
  );
}
