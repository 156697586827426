import Link from 'next/link';
import { InView } from 'react-intersection-observer';

import { objectToPromoEventTransformer, selectPromotion, viewPromotion } from '../../utils/analytics';
import FormattedImage from '../FormattedImage/FormattedImage';

import styles from './OfferBannerSection.module.scss';

export default function OfferBanner({ banner, sections, title = 'links under home banner' }) {
  function onClickLink(section) {
    const index = sections.indexOf(section);
    const name = section?.text;
    const image = section?.icon?.path;
    const promo = objectToPromoEventTransformer(name, image, title, index);
    selectPromotion(promo);
  }

  function onView(isVisible, section) {
    if (!isVisible) return;
    const index = sections.indexOf(section);
    const name = section?.text;
    const image = section?.icon?.path;
    const promo = objectToPromoEventTransformer(name, image, title, index);

    viewPromotion(promo);
  }

  return (
    <InView
      as="div"
      key={banner.icon?.id}
      className={styles.bannerContainer}
      triggerOnce
      onChange={isVisible => onView(isVisible, banner)}
    >
      <p className={styles.bannerTitle}>{banner.text}</p>

      <Link href={banner.redirect_url || '#'}>
        <a className={styles.imageContainer} onClick={() => onClickLink(banner)} aria-label={banner?.text}>
          <FormattedImage
            path={banner.icon.path}
            alt={banner.text || 'προσφορά'}
            width={320}
            height={220}
            loading="lazy"
          />
        </a>
      </Link>
    </InView>
  );
}
