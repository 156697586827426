import styles from './CarouselSectionSlideImage.module.scss';

export default function CarouselSectionSlideImage({ path, alt, index }) {
  const loading = index === 0 ? 'priority' : 'lazy';
  const fetchpriority = index === 0 ? 'high' : 'auto';
  return (
    <picture>
      <source
        srcSet={`${path}?w=720&format=webp`}
        type="image/webp"
        media="(max-width: 360px)"
        width="720"
        height="192"
      />
      <source srcSet={`${path}?w=720`} type="image/jpeg" media="(max-width: 360px)" width="360" height="96" />

      <source
        srcSet={`${path}?w=750&format=webp`}
        type="image/webp"
        media="(max-width: 375px)"
        width="750"
        height="200"
      />
      <source srcSet={`${path}?w=750`} type="image/jpeg" media="(max-width: 375px)" width="375" height="100" />

      <source
        srcSet={`${path}?w=818&format=webp`}
        type="image/webp"
        media="(max-width: 414px)"
        width="818"
        height="222"
      />
      <source srcSet={`${path}?w=818`} type="image/jpeg" media="(max-width: 414px)" width="414" height="111" />

      <source
        srcSet={`${path}?w=1200&format=webp`}
        type="image/webp"
        media="(max-width: 600px)"
        width="1200"
        height="320"
      />
      <source srcSet={`${path}?w=1200`} type="image/jpeg" media="(max-width: 600px)" width="600" height="160" />

      <source
        srcSet={`${path}?w=1908&format=webp`}
        type="image/webp"
        media="(max-width: 1024)"
        width="1908"
        height="508"
      />
      <source srcSet={`${path}?w=1908`} type="image/jpeg" media="(max-width: 1024px)" width="1024" height="273" />

      <source
        srcSet={`${path}?w=2210&format=webp`}
        type="image/webp"
        media="(max-width: 1252px)"
        width="2110"
        height="562"
      />
      <source srcSet={`${path}?w=2110`} type="image/jpeg" media="(max-width: 1252px)" width="1252" height="334" />

      <source
        srcSet={`${path}?w=2548&format=webp`}
        type="image/webp"
        media="(max-width: 1366px)"
        width="2548"
        height="680"
      />
      <source srcSet={`${path}?w=2548`} type="image/jpeg" media="(max-width: 1366px)" width="1366" height="365" />

      <source
        srcSet={`${path}?w=2740&format=webp`}
        type="image/webp"
        media="(max-width: 1440px)"
        width="2740"
        height="730"
      />
      <source srcSet={`${path}?w=2740`} type="image/jpeg" media="(max-width: 1440px)" width="1440" height="411" />

      <source
        srcSet={`${path}?w=3042&format=webp`}
        type="image/webp"
        media="(max-width: 1540px)"
        width="3042"
        height="812"
      />
      <source srcSet={`${path}?w=3042`} type="image/jpeg" media="(max-width: 1540px)" width="1540" height="411" />

      <source
        srcSet={`${path}?w=3082&format=webp`}
        type="image/webp"
        media="(max-width: 1920px)"
        width="3082"
        height="822"
      />
      <source srcSet={`${path}?w=3082`} type="image/jpeg" media="(max-width: 1920px)" width="1920" height="512" />

      <img
        srcSet={`${path}`}
        className={styles.img}
        alt={alt}
        loading={loading}
        // eslint-disable-next-line react/no-unknown-property
        fetchpriority={fetchpriority}
      />
    </picture>
  );
}
