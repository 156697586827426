import classNames from 'classnames';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

import styles from './NextArrow.module.scss';

export default function NextArrow({ onClick, carousel, smallArrow, leaflet, category }) {
  return (
    <button
      className={classNames({
        [styles.categoryContainer]: category,
        [styles.container]: !carousel,
        [styles.carouselContainer]: carousel,
        [styles.leaflet]: leaflet,
        [styles.smallArrow]: smallArrow,
      })}
      onClick={onClick}
      aria-label="next"
    >
      <MdOutlineKeyboardArrowRight className={styles.icon} alt="Arrow forward - Arrow right" />
    </button>
  );
}
